import React from "react"
import {Link} from "gatsby";
import WorkArrow from "./workArrow";

function WorkBox({ ...props }){
    if(props.work.type === 'image'){
        var image = require('../works/'+props.work.image);
    }
    if(props.work.type === 'video'){
        var video = require('../works/'+props.work.image+'.webm');
        var videoMP4 = require('../works/'+props.work.image+'.mp4');
    }

    return (
        <Link to={'/works/'+props.work.slug}>
            <div className="work-box">
                <div className={'image'}>
                    {props.work.type === 'image' && <img src={image.default} alt={props.work.title}/>}
                    {props.work.type === 'video' &&
                        <video autoPlay={true} preload={'none'} loop={props.work.loop} playsInline={true} muted={true}>
                            <source src={videoMP4.default} type='video/mp4;codecs="hvc1"'/>
                            <source src={video.default} type="video/webm" />
                        </video>
                    }
                </div>
                <h2 className={'title'}>{props.work.title} <WorkArrow/></h2>
                <div className={'subtitle'}>{props.work.subtitle}</div>
            </div>
        </Link>
    )
}

export default WorkBox
