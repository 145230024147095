import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";
import WorkBox from "../components/work-box";
import {Helmet} from "react-helmet";

import works from "../contents/works.json";

const WorksPageOLD = () => {
    return (
        <main>
            <Helmet>
                <title>Our latest works | Web Agency in Budapest, Hungary | Designatives</title>
                <meta name="description" content={"Some of our latest works in web design and development, logo & branding, print design, app UI/UX design and application development"}/>
            </Helmet>
            <Header/>
            <Layout>
                <div className={'works-page'}>
                    <section className="">
                        <div className="container">
                            <div className="row h-100 align-items-center">
                                <div className={'col-12 col-md-6 order-2 order-md-1'}>
                                    <h1 className={'level1'}>Our Work</h1>
                                </div>
                            </div>
                            <div className="row">
                                {works.map((work, index) => {
                                    return (
                                        work.visible && <div key={index} className={'col-12 col-md-6'}>
                                            <WorkBox index={index} work={work} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                </div>

            </Layout>
            <Footer/>
        </main>
    )
}

export default WorksPageOLD
